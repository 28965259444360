<template>
	<div v-if="loading" id="receipt-content-page" class="text-left text-sm p-6 pt-2 print-area receipt">
		<span v-for="index in 2" :key="index">
			<div v-if="index != 1" class="page-break"></div>
			<ReceiptContent
				:order="order"
				:total_product="total_product"
				:payments="payments"
				:online_order_no="online_order_no"
				:index="index"
			/>
		</span>
	</div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useGetOrderByOrderNo } from '@/js/pos_order';
import ReceiptContent from '@/components/receipt/ReceiptContent.vue';

export default {
	name: 'Receipt',
	components: {
		ReceiptContent,
	},
	setup() {
		const { order, total_product, getOrderByOrderNo, payments, online_order_no, loading } = useGetOrderByOrderNo();
		const order_no = ref('');
		const route = useRoute();

		onMounted(async () => {
			order_no.value = await route.params.order_no;
			await getOrderByOrderNo(route.params.order_no);
			await window.print();
			window.onfocus = function(){ window.close();}
		});
		return { order, order_no, loading, payments, online_order_no, total_product };
	},
};
</script>
<style scope>
@page {
	margin: 0;
}
@font-face {
	font-family: myFirstFont;
	src: url(../../assets/font/f25_bank_printer/F25_Bank_Printer_Bold.ttf);
}
@font-face {
	font-family: myFirstFont2;
	src: url(../../assets/font/kravitz/test.TTF);
}
@font-face {
	font-family: TahomaFont;
	src: url(../../assets/font/Tahoma-Font/TAHOMA_0.TTF);
}
#receipt-content-page {
	font-family: myFirstFont;
	width: 80mm;
}
</style>
